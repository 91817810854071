var ieVersion = (function() { if (new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null) { return parseFloat( RegExp.$1 ); } else { return false; } })();

$(function()
{
	// CSRF fix

	var csrfInputName = $('meta[name="csrfTokenName"]').attr('content');
	var csrfInputValue = $('meta[name="csrfTokenValue"]').attr('content');

	$('input[name="' + csrfInputName + '"]').val(csrfInputValue);

	// IE Notice

	if(ieVersion !== false && ieVersion <= 10)
	{
		$('div#ienotice').show();
	}

	// Scroll to first form error on page load

	if($('ul.errors').length)
	{
		$('html, body').animate({
			scrollTop: $('ul.errors').first().offset().top + 'px'
		}, 'fast');
	}

	// Homepage

	if($('#banner').length > 0)
	{
		var $banner = $('#banner');

		if($banner.find('.banner-image-slide').length > 1)
		{
			$banner.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
				arrows: true,
				autoplay: true,
  			autoplaySpeed: 6000,
			});
		}
	}

	$('#site-search button').on('click', function(e)
	{
		if(!$('#site-search').hasClass('open'))
		{
			$('#site-search').addClass('open');

			e.preventDefault();
			return false;
		}
	});

	if($('.case-studies-holder').length > 0)
	{
		$('.case-studies-holder .case-studies .row').slick({
			slidesToShow: 3,
			slidesToScroll: 3,
			dots: true,
			arrows: true,
			focusOnSelect: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					}
				},
			]
		});
	}

	if($('.disable').length > 0)
	{
		$('.disable').click(function(e){
			e.preventDefault();
		});
	}

});

function csrfTokenData()
{
	var data = {};

	data[$('meta[name="csrfTokenName"]').attr('content')] = $('meta[name="csrfTokenValue"]').attr('content');

	return data;
}

$(function()
{
	var $alert = $('div#site-alert');
	var $alertClose = $alert.find('a#site-alert-close');

	$alertClose.on('click', function()
	{
		Cookies.set('alert', $alert.data('expiry'));

		$alert.slideUp();
	});
});

$(function()
{
	// isOnScreen - http://stackoverflow.com/a/23222523

	$.fn.isOnScreen = function()
	{
		var win = $(window);

		var viewport = {
			top: win.scrollTop(),
			left: win.scrollLeft()
		}

		viewport.right = viewport.left + win.width();
		viewport.bottom = viewport.top + win.height();

		var bounds = this.offset();
		bounds.right = bounds.left + this.outerWidth();
		bounds.bottom = bounds.top + this.outerHeight();

		return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
	}

	// Loader

	var $loadContainer = $('div#ajax-container');
	var $lastChild = $('div#ajax-container').children().last();

	// ajax/section/offset/limit/order/sort

	var section = $loadContainer.data('section');
	var offset = parseInt($loadContainer.data('limit'), 10);
	var limit = parseInt($loadContainer.data('limit'), 10);
	var order = $loadContainer.data('order');
	var sort = $loadContainer.data('sort');

	var year = $loadContainer.data('year');
	var month = $loadContainer.data('month');
	var cat = $loadContainer.data('cat');

	// Loading functions

	var loading = false;
	var shouldLoad = $loadContainer.length > 0;

	function load()
	{
		if(loading)
		{
			return false;
		}

		loading = true;

		if(cat == "")
		{
			cat = 'all';
		}

		var url = '/ajax/' + section + '/' + offset.toString() + '/' + limit.toString() + '/' + order + '/' + sort + '/' + cat;


		if(year && month)
		{
			url += '/' + year + '/' + month;
		}

		$.post(url, csrfTokenData(), function(data)
		{
			data = $.trim(data);
			
			if(data)
			{
				// Add new children

				$loadContainer.append(data);

				// Reset last child

				$lastChild = $('div#ajax-container').children().last();

				// Update the offset

				offset += limit;
			}
			else
			{
				// Set to not try load anymore

				$loadContainer.append('<div class="col-md-12"><p class="no-results">No more results to show, please try a different search.</p></div>');

				shouldLoad = false;
			}

			// Reset loading state

			loading = false;

			// Reapply match height elements

			var attributes = [];

			$('[data-mh]').each(function (i, e)
			{
				var dataName = $(e).data('mh');

				if (attributes.indexOf(dataName) === -1)
				{
					attributes.push(dataName);
				}
			});

			setTimeout(function()
			{
				$.each(attributes, function(i, e)
				{
					$('[data-mh="' + e + '"]').matchHeight();
				})
			}, 500);
		});
	}

	// Trigger

	$(window).on('scroll', function()
	{
		if(shouldLoad && $lastChild.length > 0 && $lastChild.isOnScreen())
		{
			load();
		}
	}).trigger('scroll');

	$('.cat-filters-button').on('click', function()
	{
		var $t = $(this);

		$('.cat-filters').slideToggle();

		if($t.html() == "Show Filters +")
		{
			$t.html('Hide Filters -');
		}
		else
		{
			$t.html('Show Filters +');
		}
	});
});
$(function()
{
	// Accordions

	$('div.accordions h3.accordion').on('click', function()
	{
		var isOpen = $(this).hasClass('open');

		if(isOpen)
		{
			$(this).removeClass('open');
		}
		else
		{
			$(this).parent().find('h3.accordion').removeClass('open');
			$(this).addClass('open');
		}
	});

	$('div.accordions h3.accordion').first().addClass('open');

	// Galleries

	$('.gallery-slider').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: true,
		arrows: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					arrows: false,
					slidesToShow: 1
				}
			}
		]
	});

	$('div.gallery-grid, div.gallery-slider').each(function()
	{
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
			},
		});
	});
});

$(function()
{
	// Open/close overlay

	var $body = $(document.body);
	var $overlayBar = $('div#overlay-bar');
	var $overlayMenu = $('div#overlay-menu');

	$('.open-overlay').on('click', function(e)
	{
		e.preventDefault();
		$body.addClass('overlay-open');

		$body.keyup(function(event) {
			if (event.keyCode == 27) {
				$('.close-overlay').trigger('click')
			}
		});
		$overlayMenu.fadeIn();
	});

	$('.close-overlay').on('click', function(e)
	{
		e.preventDefault();

		$body.off('keyup')
		$body.removeClass('overlay-open');
		$overlayMenu.fadeOut();
	});

	// Expand/contract inside overlay

	$('div#overlay-menu nav > ul > li > a span').on('click', function(e)
	{
		e.preventDefault();

		var $childMenu = $(this).parents('li').first().children('ul');

		if($childMenu.is(':visible'))
		{
			$(this).find('i').removeClass('ion-ios-minus-empty').addClass('ion-ios-plus-empty');

			$childMenu.hide();
		}
		else
		{
			$(this).find('i').removeClass('ion-ios-plus-empty').addClass('ion-ios-minus-empty');

			$childMenu.show();
		}
	});

	// Scroll show/hide overlay bar

	var $mobileBar = $('nav#overlay-bar');
	var lastScrollTop = 0;

	$(window).on('scroll', function(e)
	{
		var st = $(this).scrollTop();

		if(st < 50)
		{
			$overlayBar.removeClass('offscreen');
		}
		else if(st > lastScrollTop)
		{
			$overlayBar.addClass('offscreen');
		}
		else
		{
			$overlayBar.removeClass('offscreen');
		}

		lastScrollTop = st;
	});


});

function recordEvent(category, action, label)
{
	if(typeof window['ga'] === 'undefined')
	{
		console.error('Attempting to track event with GA not installed, please check before go-live!');
		console.error('Event:', category + ', ' + action + ', ' + label);

		return;
	}

	ga('send', 'event', category, action, label);
}

$(function()
{
	$.expr[':'].external = function(obj)
	{
		if(obj.tagName.toLowerCase() !== 'a')
		{
			return false;
		}
		
		return obj.href && !obj.href.match(/^mailto:/) && !obj.href.match(/^javascript:/) && (obj.hostname.replace(/^www\./i, '') !== document.location.hostname.replace(/^www\./i, ''));
	};
	
	$.expr[':'].email = function(obj)
	{
		if(obj.tagName.toLowerCase() !== 'a')
		{
			return false;
		}

		return obj.href && obj.href.match(/^mailto:/);
	};
	
	$('a:external, a:email').attr({
		target: '_blank',
		rel: 'external'
	});

	$('a:external').click(function()
	{
		recordEvent('External Link', 'Click', this.hostname.replace(/http(s)?:\/\//i));
	});
	
	$('a:email').click(function()
	{
		recordEvent('Mailto', 'Click', $(this).attr('href').substring(7));
	});
});